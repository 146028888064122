import ComboBox from "@/components/Elements/Autocomplete";
import ButtonForm from "@/components/Elements/Button/Form";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

export default function SearchCarForm({ tireMake, mini = null, closeAllDialog = null }){
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [showTireImage, setShowTireImage] = useState(false);
    const { register, handleSubmit, watch, trigger, control, getValues, setValue, reset, formState: { errors, isValid } } = useForm();
    const [tireSizePop, setTireSizePop] = useState(false);
    const [tireList, setTireList] = useState([]);
    const [tireValue, setTireValue] = useState([]);
    const onSubmitTo = async (data) => {
        setLoading(true);
        try {
            const ts = JSON.parse(tireValue);
            if(router.query.name){
                router.query.b = router.query.name.toUpperCase();
                delete router.query.name;
            }
            delete router.query.section_width;
            delete router.query.aspect_ratio;
            delete router.query.rim_size;
            delete router.query.search;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'search_now',
                search_by: 'car_model',
                make: data.make,
                model: data.model,
                year: data.year,
                width: "",
                aspect_ratio: "",
                diameter: "",
            });
            let q = {
                ...router.query,
                "car_make": data.make,
                "car_model": data.model,
                "car_year": data.year,
                "section_width": ts?.section_width,
                "aspect_ratio": ts?.aspect_ratio,
                "rim_size": ts?.rim_size,
                "sn": "y",
                "v": "1",
            };
            if(mini){
                setTireSizePop(false);
                setLoading(false);
            }
            if(typeof closeAllDialog == "function"){
                q = {
                    "car_make": data.make,
                    "car_model": data.model,
                    "car_year": data.year,
                    "section_width": ts?.section_width,
                    "aspect_ratio": ts?.aspect_ratio,
                    "rim_size": ts?.rim_size,
                    "sn": "y",
                    "v": "1",
                };
                setLoading(false);
                setTireSizePop(false);
                closeAllDialog();
                router.push({
                    pathname: "/shop",
                    query: q,
                });
            }
            else{
                router.push({
                    pathname: "/shop",
                    query: q,
                },
                undefined, { shallow: true});
            }
        } catch (error) {
            console.log(error);
        }
    };

    const searchTireSize = async () => {
        trigger();
        if(isValid){
            const data = getValues();
            setLoading(true);
            const tire_sizes = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/car_tire_sizes?car_make=${data.make}&car_model=${data.model}&car_year=${data.year ? data.year : ''}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'GET',
            });
            const sku_list = await tire_sizes.json();
            if(sku_list.length > 0){
                const val = {
                    section_width: sku_list[0].section_width,
                    aspect_ratio: sku_list[0].aspect_ratio,
                    rim_size: sku_list[0].rim_size,
                };
                setTireValue(JSON.stringify(val));
                setValue('tire_sizes', JSON.stringify(val), { shouldDirty: true });
                setTireSizePop(true);
                setTireList(sku_list);
            }
            setLoading(false);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'search_now',
                search_by: 'car_model',
                make: data.make,
                model: data.model,
                year: data.year,
                width: "",
                aspect_ratio: "",
                diameter: "",
            });
        }
    };

    const handleTireValue = (tire) => {
        setTireValue(tire);
    }
    const onError = data => console.log(data);

    const [tireMakes, setTireMakes] = useState([]);
    const [selectedTireMake, setSelectedTireMake] = useState(null);

    const [tireModels, setTireModels] = useState([]);
    const [selectedTireModel, setSelectedTireModel] = useState(null);

    const [tireYears, setTireYears] = useState([]);
    const [selectedTireYear, setSelectedTireYear] = useState(null);

    const clearFilter = () => {
        setSelectedTireMake(null);
        setSelectedTireModel(null);
        setTireModels([]);
        setSelectedTireYear(null);
        setTireYears([]);
    };

    const handleChangeTireMake = (make, init = null) => {
        setSelectedTireMake(make);
        setSelectedTireModel(null);
        setTireModels([]);
        setSelectedTireYear(null);
        setTireYears([]);
        let arrModels = [];
        tireMake.find((tire, i) => {
            if(tire.car_make == make.value){
                tire.model.map((m, index) => {
                    arrModels.push({label: m.car_model, value: m.car_model});
                });
            }
        });
        setTireModels(arrModels);
        if(init){
            if(router.query.car_model){
                setValue('model', router.query.car_model, { shouldDirty: true });
                let carModel = {label: router.query.car_model, value: router.query.car_model};
                handleChangeTireModel(carModel, 1);
            }
        }
    };

    const handleChangeTireModel = (model, init = null) => {
        setSelectedTireModel(model);
        setSelectedTireYear(null);
        setTireYears([]);
        let arrYears = [];
        tireMake.find((tire, i) => {
            if(tire.car_make == selectedTireMake?.value){
                tire.model.map((m, inx) => {
                    if(m.car_model == model.value){
                        m.year.map((y, index) => {
                            arrYears.push({label: y.car_year, value: y.car_year});
                        });
                    }
                });
            }
        });
        setTireYears(arrYears);
        if(init){
            if(router.query.car_year){
                setValue('year', router.query.car_year, { shouldDirty: true });
                let carYear = {label: router.query.car_year, value: router.query.car_year};
                handleChangeTireYear(carYear, 1);
            }
        }
    };

    const handleChangeTireYear = (year, init = null) => {
        setSelectedTireYear(year);
    };

    useEffect(()=>{
        if(!router.isReady) return;
        if(router.query.car_make){
            setValue('make', router.query.car_make, { shouldDirty: true });
            let carMake = {label: router.query.car_make, value: router.query.car_make};
            handleChangeTireMake(carMake, 1);
        }
        else{
            clearFilter();
            reset();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router]);

    useEffect(()=>{
        if(!tireMake) return;
        let arrMakes = [];
        tireMake.map((tire, index) => {
            arrMakes.push({label: tire.car_make, value: tire.car_make});
        });
        setTireMakes(arrMakes);
    },[tireMake]);
    return(
        <div className={`w-full mx-auto rounded-md ${mini ? 'px-1' : 'px-4'}`}>
            <div className="w-full bg-no-repeat bg-[length:604px_180px] bg-[10px_35px] pt-0 rounded-lg">
                <form className={`${mini ? 'px-1 py-1' : 'px-4 py-2 rounded-b'}`} onSubmit={handleSubmit(onSubmitTo, onError)}>
                    <div className={`grid ${mini ? 'grid-cols-1' : 'sm:grid-cols-3 grid-cols-1'} gap-2 mb-2`}>
                        <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { isTouched, isDirty, error },
                            }) => {
                                return(
                                    <Fragment>
                                        <ComboBox
                                            id="make"
                                            name="make"
                                            label="Make"
                                            error={errors.make != null}
                                            helperText={errors.make && errors.make?.message}
                                            onChange={(e, attr) => {
                                                if(attr){
                                                    onChange(attr.value);
                                                    handleChangeTireMake(attr);
                                                }
                                            }}
                                            value={selectedTireMake}
                                            options={tireMakes}
                                            isOptionEqualToValue={(option, value) => option.label === value}
                                        />
                                    </Fragment>
                                )
                            }}
                            name="make"
                            control={control}
                            rules={{ required: "Please select a tire make." }}
                        />
                        <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { isTouched, isDirty, error },
                            }) => {
                                return(
                                    <Fragment>
                                        <ComboBox
                                            id="model"
                                            name="model"
                                            label="Model"
                                            error={errors.model != null}
                                            helperText={errors.model && errors.model?.message}
                                            onChange={(e, attr) => {
                                                if(attr){
                                                    onChange(attr.value);
                                                    handleChangeTireModel(attr);
                                                }
                                            }}
                                            value={selectedTireModel}
                                            options={tireModels}
                                            isOptionEqualToValue={(option, value) => option.label === value}
                                        />
                                    </Fragment>
                                )
                            }}
                            name="model"
                            control={control}
                            rules={{ required: "Please select a tire model." }}
                        />
                        <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { isTouched, isDirty, error },
                            }) => {
                                return(
                                    <Fragment>
                                        <ComboBox
                                            id="year"
                                            name="year"
                                            label="Year"
                                            error={errors.year != null}
                                            helperText={errors.year && errors.year?.message}
                                            onChange={(e, attr) => {
                                                if(attr){
                                                    onChange(attr.value);
                                                    handleChangeTireYear(attr);
                                                }
                                            }}
                                            value={selectedTireYear}
                                            options={tireYears}
                                            isOptionEqualToValue={(option, value) => option.label === value}
                                        />
                                    </Fragment>
                                )
                            }}
                            name="year"
                            control={control}
                            rules={{ required: false }}
                        />
                    </div>
                    <button
                        type="button"
                        className="search-button"
                        onClick={()=>searchTireSize()}
                    >
                        Search for Tires
                    </button>
                    <div className="w-full mt-2">
                        <label className="text-blue-600 italic cursor-help font-semibold text-lg drop-shadow-xl" onClick={()=>router.push('/quotation')}>Can&rsquo;t find your car? Click here</label>
                    </div>
                    <Dialog
                        className="fixed inset-0 z-[1201] overflow-y-auto"
                        onClose={() => {setTireSizePop(true);}}
                        open={tireSizePop}
                    >
                            <DialogTitle className="bg-red-600 text-white font-semibold !py-2">Available Tire Sizes</DialogTitle>
                            <DialogContent className="!py-0">
                                <div className="flex flex-col px-2 py-1">
                                    <p className="text-3xl font-semibold text-black text-left uppercase">
                                        {getValues('make')} {getValues('model')} {getValues('year')}
                                    </p>
                                    <div className={`transition-all duration-500 ease-in-out bg-find-this bg-no-repeat sm:bg-[length:422px_240px] bg-[length:302px_150px] bg-top ${showTireImage ? 'sm:pt-64 pt-36' : 'pt-px'}`}></div>
                                    <div className={`text-base font-semibold text-black bg-white text-left italic`}>
                                        Note: Please Check your <a onClick={()=>setShowTireImage(!showTireImage)} className="text-blue-500 cursor-pointer underline">Actual Tire size</a>
                                    </div>
                                    <Controller
                                        render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                                formState,
                                                }) => {
                                                    return (
                                                    <FormControl className="pt-2" error={errors.tire_sizes != null}>
                                                        <FormLabel id="tire-radio-buttons-group-label">Available Tire Size</FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby="tire-radio-buttons-group-label"
                                                            value={tireValue}
                                                            name="tire_sizes"
                                                            onChange={(e, attr) =>{
                                                                onChange(attr); handleTireValue(attr);
                                                            }}
                                                        >
                                                            {tireList.map((tire, index) => {
                                                                const val = {
                                                                    section_width: tire.section_width,
                                                                    aspect_ratio: tire.aspect_ratio,
                                                                    rim_size: tire.rim_size,
                                                                };
                                                                return (
                                                                    <FormControlLabel key={index} value={JSON.stringify(val)} control={<Radio />} label={tire.section_width+'/'+tire.aspect_ratio+' '+tire.rim_size} />
                                                                )
                                                            })}
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.tire_sizes && errors.tire_sizes?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        name="tire_sizes"
                                        control={control}
                                        rules={{ required: 'Please select available tire size.', maxLength: 80 }}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className="grid grid-cols-2 gap-2 w-full">
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-sm font-medium text-red-500 border border-red-500 rounded-md hover:text-white hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        onClick={(e) => {
                                            setTireSizePop(false);
                                        }}
                                        >
                                        Back
                                    </button>
                                    <button
                                        type="button"
                                        onClick={()=>handleSubmit(onSubmitTo, onError)()}
                                        className="flex flex-row justify-center items-center align-middle text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                    >
                                        {loading ? (
                                            <span className="flex flex-row justify-between align-middle items-center">
                                                <svg className="animate-spin" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m19.66 9.64l-.36-.94l1.86-.7c-.92-2.12-2.56-3.82-4.62-4.86l-.8 1.78l-.92-.42l.8-1.8C14.5 2.26 13.28 2 12 2c-1.06 0-2.08.22-3.04.5l.68 1.84l-.94.36L8 2.84c-2.12.92-3.82 2.56-4.86 4.62l1.78.8l-.42.92l-1.8-.8C2.26 9.5 2 10.72 2 12c0 1.06.22 2.08.5 3.04l1.84-.68l.36.94l-1.86.7c.92 2.12 2.56 3.82 4.62 4.86l.8-1.78l.92.42l-.8 1.8c1.12.44 2.34.7 3.62.7c1.06 0 2.08-.22 3.04-.5l-.68-1.84l.94-.36l.7 1.86c2.12-.92 3.82-2.56 4.86-4.62l-1.78-.8l.42-.92l1.8.8c.44-1.12.7-2.34.7-3.62c0-1.06-.22-2.08-.5-3.04l-1.84.68m-5.36 7.9c-3.06 1.26-6.58-.18-7.84-3.24s.18-6.58 3.24-7.84s6.58.18 7.84 3.24a5.986 5.986 0 0 1-3.24 7.84Z"/></svg>
                                                <span className="ml-2">Searching...</span>
                                            </span>
                                        ): (
                                            <span>Continue</span>
                                        )}
                                    </button>
                                </div>
                            </DialogActions>
                    </Dialog>
                </form>
            </div>
        </div>
    );
}
