import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { alpha, styled  } from '@mui/material/styles';
import { Popper } from '@mui/material';

const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        border: '1px solid #fee2e2',
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        '& .MuiInputBase-input': {
            paddingBottom: '2.5px',
            paddingTop: '15px',
            paddingLeft: '10px',
            fontWeight:800,
        },
        '&.Mui-disabled':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        },
        '&.Mui-disabled input':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        },
        '& .MuiAutocomplete-endAdornment button':{
            color: "#dc2626",
        }
    },
    '& .Mui-focused': {
        backgroundColor: '#ffffff',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
    },
    '& fieldset':{
        zIndex: "-1",
        backgroundColor: "#ffffff",
        border: '1px solid #e2e2e1',
        top: 0,
        '& legend':{
            display: 'none',
        },
    },
    '& .MuiFormLabel-root': {
        fontWeight: 800,
        top: '-7px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
        top: '12px',
        color: theme.palette.neutral.main,
        boxShadow: 'none',
        border: 'none',
    },
    '& .MuiFormLabel-filled':{
        top: '12px',
    },
    '& .MuiInputBase-root': {
        padding: 4,
    },
}));
const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        fontWeight: 800,
    },
});
export default function ComboBox({label, id, name, onChange, freeSolo = false, multiple = false, disabled = false, renderOption = null, filterOptions, loading, loadingText, onInputChange, value, defaultValue, getOptionSelected, error, helperText, options, className}) {
  return (
    <Autocomplete
        blurOnSelect="touch"
        PopperComponent={StyledPopper}
        multiple={multiple}
        disabled={disabled}
        freeSolo={freeSolo}
        disablePortal
        loading={loading}
        loadingText={loadingText}
        id={id}
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        filterOptions={filterOptions}
        defaultValue={defaultValue}
        onInputChange={onInputChange}
        className={className}
        getOptionLabel={(option) => option.label || ""}
        renderOption={renderOption}
        renderInput={(params) => <RedditTextField error={error} variant="outlined" helperText={helperText} {...params} label={label} />}
    />
  );
}