import LoadingButton from "@/components/Loading/Button";

export default function ButtonForm({type = 'button', classN, onClick, loading, label, llabel, color = 'primary'}){
    return(
        <button
            type={type}
            onClick={onClick}
            disabled={loading}
            className={`shadow inline-flex items-center justify-center leading-6 font-semibold w-full  px-4 py-2 rounded-md border ${(color == 'primary') ? 'text-white bg-red-600 hover:text-red-600 hover:bg-white border-red-500 hover:border-red-600/50' : 'text-red-600 bg-white hover:text-blue-600 border-blue-400 hover:border-blue-600/50'} `+classN}        >
            {loading ? (
                <LoadingButton classN={'w-5 h-5 mr-2'} label={llabel}/>
            ):(
                <div className="flex flex-row items-center justify-center" dangerouslySetInnerHTML={{ __html: label }} />
            )}
        </button>
    )
}