/* eslint-disable @next/next/no-img-element */
import { styled, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import SearchCarForm from "./Car";
import SearchSizeForm from "./Size";
import { useRouter } from "next/router";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      height:"5px",
      bottom: "7px",
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: "95%",
      width: '100%',
      height:"5px",
      bottom: "7px",
      backgroundColor: '#dc2626',
    },
});
export default function SearchForm({ className, tireMake, tireWidth, mini = null, activeTab = 0, closeAllDialog = null}){
    const router = useRouter();
    const [tabValue, setTabValue] = useState(activeTab);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    useEffect(()=>{
        if(!router.isReady) return;
        if(activeTab == 0){
            if(router.query.car_make && router.query.car_year && router.query.car_model){
                setTabValue(1);
            }
            else if(router.query.section_width && router.query.aspect_ratio && router.query.rim_size){
                setTabValue(0);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router.isReady]);
    return(
        <div className={` ${className} flex flex-col bg-red-100/50 rounded`}>
            <StyledTabs
                className={`w-full ${mini ? 'px-1' : 'px-3'}`}
                variant="fullWidth"
                value={tabValue}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="Search tab"
            >
                <Tab
                    className="!font-semibold !bg-white/80 !rounded-md !my-1 sm:!mr-1 !mr-px !ml-1 !shadow-lg !normal-case"
                    value={0}
                    icon={
                        <img className="w-6 h-6 mx-auto" src={'/assets/images/search_by_tire.webp'} alt="search_by_tire" />
                    }
                    label={
                        <div className="sm:text-lg">
                            {mini ? (
                                <span className="text-shadow shadow-red-100">
                                    <p>Search By</p>
                                    <p>Tire Size</p>
                                </span>
                            ) : (
                                <span className="text-shadow shadow-red-100">Search By Tire Size</span>
                            )}
                        </div>
                    }
                />
                <Tab
                    className="!font-semibold !bg-white/80 !rounded-md !my-1 sm:!ml-1 !ml-px !mr-1 !shadow-lg !normal-case"
                    value={1}
                    icon={
                        <img className="w-6 h-6 mx-auto" src={'/assets/images/search_by_car.webp'} alt="search_by_car" />
                    }
                    label={
                        <div className="sm:text-lg">
                            {mini ? (
                                <span className="text-shadow shadow-red-100">
                                    <p>Search By</p>
                                    <p>Car Model</p>
                                </span>
                            ) : (
                                <span className="text-shadow shadow-red-100">Search By Car Model</span>
                            )}
                        </div>
                    }
                />
            </StyledTabs>
            <TabPanel value={tabValue} index={0}>
                <SearchSizeForm tireWidth={tireWidth} mini={mini} closeAllDialog={closeAllDialog}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <SearchCarForm tireMake={tireMake} mini={mini} closeAllDialog={closeAllDialog} />
            </TabPanel>
        </div>
    );
}